

@use '../../../../style/import' as *;


//////////////
// Error404 //
//////////////


// Titan colors
// None yet

// Theme colors
$errPrimaryColor: $THEME_PRIMARY_COLOR;
$errPrimaryColorHover: $THEME_PRIMARY_COLOR_HOVER;
$errPrimaryColorText: $THEME_PRIMARY_COLOR_TEXT;
$errBackground: $THEME_BACKGROUND;
$errTextColor: $THEME_TEXT_COLOR;

// General variables
$errActionWidth: 200px;
$errHeaderPadding: 120px;
$errIconWidth: 80px;
$errMargin: 10px;


.Error404 {
	
	.errorHeader {

		position: relative;
		overflow: auto;
		background-image: url('../img/lost1.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		.titleWrapper {

			overflow: auto;
			box-shadow: 0 0 $errMargin $errMargin/2 $errTextColor inset;
			background: rgba($errTextColor, 0.8);

			.pageTitle {

				margin: $errHeaderPadding 0;
				text-align: center;
				color: $errBackground;

				.pageErrorCode {
					display: inline-block;
					vertical-align: middle;
					margin: 0 $errMargin 0 0;
					font-size: 48px;
					font-weight: 700;
				}

				.pageTitleCopy {
					display: inline-block;
					vertical-align: middle;
					font-size: 40px;
					font-weight: 300;
				}
			}
		}
	}

	.errorBody {

		.bodyCopy {
			margin: 3*$errMargin 0;
			padding: 0 3*$errMargin;
			text-align: center;
			font-size: 24px;
			font-weight: 300;
		}

		.bodyActionHeader {
			margin: 5*$errMargin 0 $errMargin 0;
			text-align: center;
			font-size: 26px;
			font-weight: 700;
			font-family: $fontLato;
		}

		.bodyActionsWrapper {

			text-align: center;

			.bodyAction {
				display: inline-block;
				vertical-align: top;
				width: $errActionWidth - 2*$errMargin;
				margin: 0 $errMargin 2*$errMargin $errMargin;

				.actionIcon {
					width: $errIconWidth;
					height: $errIconWidth;
					margin: 0 auto $errMargin auto;

					.icon {
						width: $errIconWidth;
						height: $errIconWidth;
					}
				}

				.actionButton {

					padding: $errMargin 2*$errMargin;
					border: none;
					border-radius: 0;
					cursor: pointer;
					background: $errPrimaryColor;
					color: $errPrimaryColorText;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					transition: background $hoverTransition;

					&:hover {
						background: $errPrimaryColorHover;
					}
				}
			}
		}
	}
}


